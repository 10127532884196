<!--
 * @Description: 房态
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:41:13
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-03-19 11:21:03
-->

<template>
  <div
    class="room_state_calendar container"
    v-loading="loading || exchange_saving"
  >
    <div class="header">
      <div class="calendar_pick">
        <div class="date_wap" @click="onOpenPicker">
          <div class="date_select">
            <span>{{ query.date }}</span>
            <el-icon :size="14" style="margin-left: 5px">
              <Calendar />
            </el-icon>
            <div class="btn_today" @click.stop="onDateToday">今</div>
          </div>
          <el-date-picker
            v-model="query.date"
            placeholder
            :editable="false"
            :readonly="false"
            :clearable="false"
            value-format="YYYY-MM-DD"
            @change="datePickChange"
            size="small"
            ref="refPicker"
            style="width: 100px"
          ></el-date-picker>
        </div>
        <div class="date_select_tip" @click="onOpenPicker">
          <el-icon
            style="margin-right: 4px"
            @click.stop="datePrevNextToggle(0)"
          >
            <ArrowLeftBold />
          </el-icon>
          <span>{{ short_date_tip }}</span>
          <el-icon style="margin-left: 4px" @click.stop="datePrevNextToggle(1)">
            <ArrowRightBold />
          </el-icon>
        </div>
      </div>
      <div class="calendar_box">
        <div class="calendar_wrap" :style="`left:${scrollLeft}px;`">
          <div class="calendar_item" v-for="item in calendar_list" :key="item">
            <div class="date" v-if="item.date == today" style="color: #409eff">
              <span>今天</span>
              <span style="margin-left: 5px">{{ item.week_text }}</span>
            </div>
            <div :class="`date ${item.is_highlight ? 'light' : ''}`" v-else>
              <span>{{ item.date_format }}</span>
              <span style="margin-left: 5px">{{ item.week_text }}</span>
            </div>

            <div class="number">
              剩{{ residueRoomNumber(item) }}/{{ group_stores.counts }}间
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body">
      <el-scrollbar>
        <div class="scroll_box">
          <div class="left">
            <div class="room_group" v-for="item in group_stores" :key="item">
              <div class="group">
                <span class="ellipsis-3">{{ item.name }}</span>
              </div>
              <div class="rooms">
                <!-- <div
                  class="item"
                  v-for="x in item.store_list"
                  :key="x"
                  @mouseenter="roomMouseEnter(x)"
                  @mouseleave="roomMouseLeave(x)"
                  @click="roomStatusHandler(x)"
                > -->
                <div
                  class="item"
                  v-for="x in item.store_list"
                  :key="x"
                >
                  <div
                    :class="`house_no ${x.clean_status == 1 ? 'dirty' : ''}`"
                  >
                    <span v-if="!x.show_status">{{ x.house_no }}</span>
                    <span class="dirty_icon" v-if="x.clean_status == 1">
                      <el-icon>
                        <BrushFilled />
                      </el-icon>
                    </span>
                  </div>
                  <!-- <div class="roomStatus" v-if="x.show_status">
                    {{ x.clean_status == 0 ? "转为脏房" : "转为净房" }}
                  </div> -->
                  <div
                    v-if="x.note"
                    style="position: absolute; right: 0; top: 0; z-index: 3"
                  >
                    <el-popover
                      placement="right"
                      title=""
                      :width="300"
                      trigger="hover"
                      content
                    >
                      <div style="cursor: pointer" @click="onOpenNote(x.note)">
                        <div class="ellipsis2">
                          {{ x.note.content }}
                        </div>
                        <div style="font-size: 12px">
                          状态：<el-link
                            :type="x.note.status == 20 ? 'primary' : 'warning'"
                            :underline="false"
                            size="small"
                            >{{ x.note.status_text }}</el-link
                          >
                        </div>
                        <div
                          class="flex flex-row flex-between"
                          style="font-size: 12px"
                        >
                          <span>{{ x.note.emp.chs_name }} </span>
                          <span>{{ x.note.create_at }}</span>
                        </div>
                      </div>
                      <template #reference>
                        <div
                          style="
                            background-color: #e6a23c;
                            font-size: 12px;
                            color: white;
                            padding: 4px;
                            transform: scale(0.8);
                          "
                        >
                          备
                        </div>
                      </template>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="canvas_box" ref="refCanvasBox">
            <div
              class="canvas_wrap"
              :style="`left:${scrollLeft}px;`"
              @dragover="(e) => e.preventDefault()"
              @drop="orderDrop"
            >
              <canvas
                id="canvas"
                @click="canvasClick"
                class="canvas"
                :width="TD_WIDTH * 30"
                :height="group_stores.counts * TD_HEIGHT"
              ></canvas>

              <!-- 预订工具条 -->
              <div
                class="tooltip"
                v-if="is_can_add && this.check_cell.length > 0"
                :style="`left:${tool_left}px;top:${tool_top}px;`"
              >
                <div
                  class="arrows"
                  :style="`left:${tool_arrow_left}px;top:${tool_arrow_top}px;`"
                ></div>
                <div class="tools">
                  <div class="btn book" @click="onOpenBookDialog">预定</div>
                  <div class="btn close" @click="onRoomClose">关房</div>
                  <div class="btn cancel" @click="onCancel">取消</div>
                </div>
              </div>

              <div
                :class="`order ${is_can_change ? 'drag' : ''} ${
                  item.is_draggable ? 'dragstart' : ''
                }`"
                v-for="item in orderList"
                :key="item.id"
                @dragstart="is_can_change ? orderDragstart($event, item) : null"
                :draggable="true"
                @drag="is_can_change ? orderDrag($event, item) : null"
                @mouseenter="orderMouseenter(item)"
                @mouseleave="orderMouseleave(item)"
                :style="`width:${item.width}px;left:${item.left}px;top:${item.top}px;background:${item.order.ota_hex_color};`"
              >
                <el-popover
                  :placement="item.placement"
                  :visible="item.visiblePopover"
                  :width="400"
                >
                  <template #reference>
                    <div
                      class="cover"
                      @click="is_can_preview_dtl ? onOpenDtl(item) : null"
                    >
                      <b style="font-size: 16px">
                        {{ item.order.contact_name }}
                      </b>
                      <div style="font-size: 12px">
                        {{ item.order.ota_name }}
                      </div>
                      <span class="owe" v-if="item.order.boolUncleared"
                        >欠</span
                      >
                      <div
                        :class="`status status_${item.status}`"
                        :style="`background-color:${item.status_color_hex};`"
                      ></div>
                    </div>
                  </template>
                  <div
                    class
                    v-if="is_can_preview_dtl"
                    @mouseenter="popoverMouseenter(item)"
                    @mouseleave="popoverMouseleave(item)"
                  >
                    <div class="flex-row item-center flex-between">
                      <span>{{ item.house_no }}</span>
                      <div class="flex-row item-center">
                        <el-tag type="success">{{ item.status_text }}</el-tag>
                        <el-button
                          size="small"
                          icon="CopyDocument"
                          style="margin-left: 10px"
                          @click="onCopyOrder(item)"
                          >复制</el-button
                        >
                      </div>
                    </div>
                    <div class="flex-row item-center">
                      <el-icon style="margin-right: 10px">
                        <User />
                      </el-icon>
                      <span>
                        {{ item.order.contact_name }}
                        {{ item.order.mobile_phone
                        }}{{
                          item.order.mobile_phone_caller
                            ? `(${item.order.mobile_phone_caller})`
                            : ""
                        }}
                      </span>
                    </div>
                    <div class="flex-row item-center" style="margin: 10px 0">
                      <el-icon style="margin-right: 10px">
                        <Calendar />
                      </el-icon>
                      <span>{{ item.checkin_date }}入住</span>
                      &nbsp;| &nbsp;
                      <span>{{ item.checkout_date }}离店</span>
                      &nbsp;| &nbsp;
                      <span>{{ item.days }}晚</span>
                    </div>
                    <div class="flex-row item-center">
                      <el-icon style="margin-right: 10px">
                        <Ship />
                      </el-icon>
                      <span>来自: {{ item.order.ota_name }}</span>
                    </div>
                    <div class="flex-row item-center" style="margin-top: 10px">
                      <el-icon style="margin-right: 10px">
                        <Ship />
                      </el-icon>
                      <span>{{ item.order.type_text }}</span>
                    </div>

                    <div
                      class="flex-row item-center flex-between order_amount_wrp"
                    >
                      <span
                        >订单总额：￥{{ item.order.decimal_total_amount }}</span
                      >
                      <span
                        >已付金额额：￥{{
                          item.order.decimal_receipt_amount
                        }}</span
                      >
                    </div>
                    <div class="flex-row item-center" style="padding-top: 10px">
                      <span>操作备注：</span>
                      <div class="flex-grow">
                        {{ item.order.operate_remark }}
                      </div>
                    </div>
                  </div>
                </el-popover>
              </div>

              <div
                v-if="drag_to_item"
                class="order"
                :style="`width:${drag_to_item.width}px;left:${drag_to_item.left}px;top:${drag_to_item.top}px;background:${drag_to_item.order.ota_hex_color};`"
              >
                <div class="cover">
                  <b>{{ drag_to_item.order.contact_name }}</b>
                  <div style="font-size: 12px">
                    {{ drag_to_item.order.ota_name }}
                  </div>
                  <span class="owe" v-if="drag_to_item.pay_amount > 0">欠</span>
                  <div
                    class="status"
                    :style="`background-color:${drag_to_item.status_color_hex};`"
                  ></div>
                </div>
              </div>

              <!-- 关房数据 -->
              <el-popover
                v-for="item in solarList"
                :key="item.id"
                :placement="item.placement"
                :width="300"
                :visible="item.visiblePopover"
              >
                <template #reference>
                  <div
                    class="solar"
                    :style="`left:${item.left}px;top:${item.top}px;`"
                    @click="onSolarClick(item)"
                    @mouseenter="solarMouseEnter(item)"
                    @mouseleave="solarMouseLeave(item)"
                  >
                    <div class="icon">
                      <el-icon :size="18">
                        <WarningFilled />
                      </el-icon>
                    </div>
                    <div class="text">{{ item.type_text }}</div>
                    <div v-if="item.checked" class="checked">
                      <el-icon color="#fff" :size="12">
                        <Check />
                      </el-icon>
                    </div>
                  </div>
                </template>
                <div class="solar_desc">
                  <div class="flex-row item-center">
                    <div class="flex-grow" style="font-weight: bold">
                      {{ item.type_text }}
                    </div>
                  </div>
                  <div class="flex-row item-center" style="padding-top: 10px">
                    <span>操作人：</span>
                    <div class="flex-grow">{{ item.emp_name }}</div>
                  </div>
                  <div class="flex-row item-center" style="padding-top: 10px">
                    <span>操作时间：</span>
                    <div class="flex-grow">{{ item.create_at }}</div>
                  </div>
                  <div class="flex-row item-center" style="padding-top: 10px">
                    <span>备注：</span>
                    <div class="flex-grow">{{ item.remark }}</div>
                  </div>
                </div>
              </el-popover>
              <!-- 开房关房工具栏 -->
              <div
                class="solar_tool"
                v-power="'seller/v1/roomState/open'"
                v-if="this.solar_selection_list.length > 0"
                :style="`left:${solar_tool.left}px;top:${solar_tool.top}px;`"
              >
                <div
                  class="arrows"
                  :style="`left:${solar_tool.arrow_left}px;top:${solar_tool.arrow_top}px;`"
                ></div>
                <div class="tools">
                  <div class="btn open" @click="onOpenSubmit">开房</div>
                  <div class="btn cancel" @click="onSolarCancel">取消</div>
                </div>
              </div>
              <!-- <div
                class="occupy record"
                v-for="item in date_record_list"
                @click="onRmOccupy(item)"
                :key="item.id"
                :style="`width:${item.width}px;left:${item.left}px;top:${item.top}px;`"
              >
                <el-icon>
                  <WarningFilled />
                </el-icon>
                <div>占用</div>
              </div>-->
            </div>
          </div>
        </div>
      </el-scrollbar>
      <div class="horizontal_scrollbar">
        <div class="left"></div>
        <div class="right">
          <el-scrollbar
            ref="horizontalScrollbar"
            @scroll="scrollHorizontal"
            wrap-class="custom_scrollbar"
            :always="true"
          >
            <div class="horizontal_scrollbar_inner"></div>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>

  <el-dialog
    title="关房"
    v-model="visibleCloseRoomDialog"
    width="600px"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      :model="closeForm"
      ref="closeForm"
      :rules="closeRules"
      label-width="80px"
    >
      <el-form-item label="类型" prop="type">
        <el-radio-group v-model="closeForm.type">
          <el-radio
            :label="item.value"
            v-for="item in enums.store_close_types"
            :key="item.value"
            >{{ item.text }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注">
        <el-col :span="20">
          <el-input v-model="closeForm.remark" type="textarea"></el-input>
        </el-col>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmitClose" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-dialog>

  <add-order
    :room_list="checkList"
    v-if="isOpenBook"
    @close="closeBook"
    :group_stores="group_stores"
    @success="addSuccess"
    :order_id="copy_order_id"
    :store_id="copy_store_id"
    :copy_store="copy_store"
  ></add-order>
  <order-dtl
    :order_id="current_order_id"
    v-if="current_order_id"
    @close="onOrderClose"
    :order_dtl="order"
  ></order-dtl>
  <note-dtl
    :item="current_note_dtl"
    @close="current_note_dtl = null"
    @change="loadNote"
  ></note-dtl>
</template>

<script>
import date_util from "@/utils/date_util";
import common_api from "@/http/common_api";
import AddOrder from "@/views/trade/order/add";
import OrderDtl from "../../trade/order/order_dtl.vue";
import NoteDtl from "@/views/note/note_dtl.vue";
export default {
  components: {
    OrderDtl,
    AddOrder,
    NoteDtl,
  },
  data() {
    return {
      calendarRadio: "日历",
      loading: false,
      //保存关房
      saving: false,
      //换房保存
      exchange_saving: false,
      visibleCloseRoomDialog: false,
      isOpenBook: false,
      visiblePopover: false,

      enums: {},
      //表格日期头列表
      calendar_list: [],
      //日历参数
      query: {
        q: "",
        days: 30,
        date: "",
      },
      //已经滚动的left值
      scrollLeft: 0,
      //选中的块
      check_cell: [],
      //工具栏位置
      tool_left: 0,
      tool_top: 0,
      tool_arrow_top: 0,
      tool_arrow_left: -4,

      //房间列表
      store_list: [],
      //显示dialog
      dialogVisible: false,
      //订单id
      current_order_id: null,
      order: null,
      closeForm: {},
      closeRules: {
        type: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
      //正常状态选中的数据{store_id:'房间id',check_in_date:'入住日期'}
      checkList: [],
      //最后选择的数据对象 checkList
      // last_check: {},
      //关房的数据列表
      solarList: [],
      //订单列表
      orderList: [],
      //分组的房源
      group_stores: [],
      //复制的订单id
      copy_order_id: "",
      //复制订单的房间id
      copy_store_id: "",
      copy_store: null,
      short_date_tip: "",
      //今天
      today: date_util.formatDate(new Date()),
      //已占用的日期
      date_record_list: [],
      //当前拖动的对象
      drag_item: null,
      drag_to_item: null,
      //表格td长宽
      TD_WIDTH: 110,
      TD_HEIGHT: 65,
      //开房的选择数据
      solar_selection_list: [],
      //开关房工具参数
      solar_tool: {},
      //是否可以查看详情
      is_can_preview_dtl: false,
      //是否可以录入预订
      is_can_add: false,
      //是否可以换房
      is_can_change: false,
      //备忘录详情
      current_note_dtl: null,
    };
  },
  props: ["shop_id"],
  watch: {
    shop_id: {
      handler() {
        // this.query.shop_id = this.shop_id;
        // this.loadData();
      },
      immediate: true,
    },
  },
  created() {
    var dt = new Date();
    this.handlerDate(new Date(dt.setDate(dt.getDate() - 2)));
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });

    this.loadData();
    //页面可见区域宽度
    this.clientWidth = document.documentElement.clientWidth;
    //是否可以查看详情
    this.is_can_preview_dtl = this.$power("seller/v1/houseOrder/dtl");
    //是否可以录入预订
    this.is_can_add = this.$power("seller/v1/houseOrder/submit");
    this.is_can_change = this.$power("seller/v1/houseOrder/exchange");
  },
  mounted() {
    this.CanvasBoxWidth = this.$refs.refCanvasBox.clientWidth;
    //可滚动的最大值
    this.ScrollMax = 3000 - this.CanvasBoxWidth;
    this.TempScrollLeft = 0;
  },
  methods: {
    /**
     * 打开日期选择
     */
    onOpenPicker() {
      this.$refs.refPicker.focus();
    },

    /**
     * 今天
     */
    onDateToday() {
      var dt = new Date();
      this.datePickChange(new Date(dt.setDate(dt.getDate() - 2)));
    },

    /**
     * 上下个月切换
     */
    datePrevNextToggle(t) {
      console.log(this.query.date);
      var dt = new Date(this.query.date);
      var endDate = new Date(dt.setDate(dt.getDate() + (t == 0 ? -30 : 30)));
      this.handlerDate(endDate);
      this.loadCalendar();
    },

    /**
     * 处理日期显示
     */
    handlerDate(startDate) {
      var dt = new Date(startDate);
      var endDate = new Date(dt.setDate(dt.getDate() + 29));
      this.query.date = date_util.formatDate(startDate);

      this.short_date_tip =
        date_util.shortDate(startDate, "/") +
        " - " +
        date_util.shortDate(endDate, "/");
    },

    /**
     * 日期选择改变
     * @param {*} date
     */
    datePickChange(value) {
      this.handlerDate(new Date(value));
      this.loadCalendar();
    },

    /**
     * canvas 横向滚动
     */
    scrollHorizontal(o) {
      this.scrollLeft = -o.scrollLeft;
      this.TempScrollLeft = Math.abs(o.scrollLeft);
    },

    /**
     * 加载日历列表
     */
    loadCalendar() {
      this.calendar_list = [];
      this.$http
        .post("seller/v1/roomState/calendar", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.calendar_list = res.data;
            this.loadOrder();
            this.loadSolar();
          }
        });
    },
    /**
     * 加载房间数据
     */
    loadData() {
      this.loading = true;
      this.$http
        .post("seller/v1/roomState/stores", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.group_stores = res.data;
            this.group_stores.counts = 0;
            this.store_list = [];
            this.group_stores.forEach((item) => {
              this.group_stores.counts += item.store_list.length;
              item.store_list.forEach((x) => {
                this.store_list.push(x);
              });
            });
            setTimeout(() => {
              this.canvasDrawTable();
            }, 100);
            this.loadCalendar();
            this.loadNote();
          }
        })
        .finally(() => {
          // this.loading = false;
        });
    },

    /**
     * 绘制表格
     */
    canvasDrawTable() {
      var canvas = document.getElementById("canvas");
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      var sizeWidth = this.TD_WIDTH;
      var sizeHeight = this.TD_HEIGHT;
      var canvasWidth = this.TD_WIDTH * 30;
      var canvasHeight = this.group_stores.counts * this.TD_HEIGHT;
      var row = Math.floor(canvasHeight / sizeHeight);
      var col = Math.floor(canvasWidth / sizeWidth);
      for (var i = 1; i <= row; i++) {
        // ctx.beginPath();
        ctx.moveTo(0, i * sizeHeight - 0.5);
        ctx.lineTo(canvasWidth, i * sizeHeight - 0.5);
        // ctx.closePath();
        ctx.strokeStyle = "#dcdfe6";
        ctx.stroke();
      }
      for (var k = 1; k <= col; k++) {
        // ctx.beginPath();
        ctx.moveTo(k * sizeWidth - 0.5, 0);
        ctx.lineTo(k * sizeWidth - 0.5, canvasHeight);
        // ctx.closePath();
        ctx.strokeStyle = "#dcdfe6";
        ctx.stroke();
      }
    },

    /**
     * canvas点击事件
     */
    canvasClick(e) {
      if (this.solar_selection_list.length > 0) {
        return;
      }
      this.convasAddCheck(e.offsetX, e.offsetY);
    },

    /**
     * 添加选中的区域
     */
    convasAddCheck(x, y) {
      var c = document.getElementById("canvas");
      var ctx = c.getContext("2d");
      var i = Math.floor(x / this.TD_WIDTH);
      var k = Math.floor(y / this.TD_HEIGHT);
      var top = k * this.TD_HEIGHT;
      var left = i * this.TD_WIDTH;
      if (this.check_cell.find((o) => o.i == i && o.k == k)) {
        this.check_cell.splice(
          this.check_cell.findIndex((o) => o.i == i && o.k == k),
          1
        );
        ctx.clearRect(left + 2, top + 2, this.TD_WIDTH - 4, this.TD_HEIGHT - 4);
        if (this.check_cell.length > 0) {
          this.setToolPosition(
            this.check_cell[this.check_cell.length - 1].i,
            this.check_cell[this.check_cell.length - 1].k
          );
        }
        return;
      }

      ctx.fillStyle = "#a0cfff";

      ctx.fillRect(left + 2, top + 2, this.TD_WIDTH - 4, this.TD_HEIGHT - 4);

      ctx.strokeStyle = "#fff";
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(left + 38, top + 25);
      ctx.lineTo(left + 47, top + 38);
      ctx.lineTo(left + 75, top + 20);
      ctx.stroke();

      this.check_cell.push({ i: i, k: k });

      this.setToolPosition(i, k);
    },

    /**
     * 工具栏位置
     */
    setToolPosition(i, k) {
      if (i < 29) {
        this.tool_left = (i + 1) * this.TD_WIDTH;
        this.tool_arrow_left = -4;
      } else {
        this.tool_left = i * this.TD_WIDTH - 45;
        this.tool_arrow_left = 38;
      }

      if (k > 0 && this.store_list.length - k < 3) {
        this.tool_top = k * this.TD_HEIGHT - 60;
        this.tool_arrow_top = 80;
      } else {
        this.tool_top = k * this.TD_HEIGHT + 20;
        this.tool_arrow_top = 8;
      }
    },

    /**
     *
     * @param {*} item
     */
    onSolarClick(item) {
      var i = item.i,
        k = item.k;
      if (item.checked) {
        item.checked = false;
        var idx = this.solar_selection_list.findIndex(
          (o) => o.store_id == item.store_id && o.date == item.date
        );
        this.solar_selection_list.splice(idx, 1);
        console.log(this.solar_selection_list);
        if (this.solar_selection_list.length > 0) {
          var x =
            this.solar_selection_list[this.solar_selection_list.length - 1];
          i = x.i;
          k = x.k;
        }
      } else {
        item.checked = true;
        this.solar_selection_list.push(item);
        item.visiblePopover = false;
      }

      if (i < 29) {
        this.solar_tool.left = (i + 1) * this.TD_WIDTH;
        this.solar_tool.arrow_left = -4;
      } else {
        this.solar_tool.left = i * this.TD_WIDTH - 45;
        this.solar_tool.arrow_left = 38;
      }

      if (k > 0 && this.store_list.length - k < 3) {
        this.solar_tool.top = k * this.TD_HEIGHT - 20;
        this.solar_tool.arrow_top = 50;
      } else {
        this.solar_tool.top = k * this.TD_HEIGHT + 20;
        this.solar_tool.arrow_top = 8;
      }
    },

    /**
     * 关房选择取消
     */
    onSolarCancel() {
      this.solar_selection_list.forEach((item) => {
        item.checked = false;
      });
      this.solar_selection_list = [];
    },

    /**
     *
     * @param {*} item
     */
    solarMouseEnter(item) {
      if (!item.checked) {
        item.visiblePopover = true;
      }
    },
    /**
     *
     * @param {*} item
     */
    solarMouseLeave(item) {
      item.visiblePopover = false;
    },

    /**
     * 获取关房的列表数据
     */
    loadSolar() {
      this.$http.post("seller/v1/roomState/solar", this.query).then((res) => {
        if (res.code == 0) {
          this.solarList = res.data;
          this.solarList.forEach((item) => {
            var i = this.calendar_list.findIndex((o) => o.date == item.date);
            var k = this.store_list.findIndex((o) => o.id == item.store_id);
            item.left = i * this.TD_WIDTH + 2;
            item.top = k * this.TD_HEIGHT + 2;
            item.k = k;
            item.i = i;
            item.placement = k >= this.store_list.length - 4 ? "top" : "bottom";
            item.visiblePopover = false;
          });
        }
      });
    },

    // /**
    //  * 获取占用的列表数据
    //  */
    // loadDateRecord() {
    //   this.$http
    //     .post("seller/v1/roomState/date_record", this.query)
    //     .then((res) => {
    //       if (res.code == 0) {
    //         this.date_record_list = res.data;
    //         this.date_record_list.forEach((item) => {
    //           var i = this.calendar_list.findIndex((o) => o.date == item.date);

    //           var k = this.store_list.findIndex((o) => o.id == item.store_id);
    //           item.left = i * 100 + 2;
    //           item.top = k * 50 + 2;
    //         });
    //       }
    //     });
    // },

    /**
     * 获取房间的脏净状态
     */
    loadCleanStores() {
      this.$http.get("seller/v1/roomState/clean_stores").then((res) => {
        if (res.code == 0) {
          this.group_stores.forEach((item) => {
            item.store_list.forEach((x) => {
              var m = res.data.find((o) => o.id == x.id);
              if (m) {
                x.clean_status = m.clean_status;
              }
            });
          });
        }
      });
    },

    /**
     * 加载房间关联的备忘录
     */
    loadNote() {
      this.$http.get("seller/v1/roomState/note").then((res) => {
        if (res.code == 0) {
          this.group_stores.forEach((item) => {
            item.store_list.forEach((x) => {
              var m = res.data.find((o) => o.store_id == x.id);
              if (m) {
                x.note = m;
              } else {
                delete x.note;
              }
            });
          });
        }
      });
    },

    /**
     * 加载订单
     */
    loadOrder() {
      this.$http
        .post("seller/v1/roomState/order", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.orderList = res.data;
            // this.loadDateRecord();
            this.handlerStoreOrder();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * 处理房源的订单归属
     */
    handlerStoreOrder() {
      this.orderList.forEach((item) => {
        item.visiblePopover = false;
        var i = this.calendar_list.findIndex(
          (o) => o.date == item.room_state_date
        );

        var k = this.store_list.findIndex((o) => o.id == item.store_id);
        item.placement = k >= this.store_list.length - 4 ? "top" : "bottom";
        item.i = i;
        item.k = k;
        item.left = i * this.TD_WIDTH + 2;
        item.top = k * this.TD_HEIGHT + 2;
        var dt =
          new Date(item.checkout_date).getTime() -
          new Date(item.room_state_date).getTime();
        var days = dt / 24 / 3600 / 1000;
        item.width = (days == 0 ? 1 : days) * this.TD_WIDTH - 4;
      });
    },

    /**
     * 处理开始日期
     */
    handleStartDate() {
      var year = this.currentDate.getFullYear();
      var month = this.currentDate.getMonth() + 1;
      var day = this.currentDate.getDate();
      this.query.date = `${year}-${month > 10 ? month : "0" + month}-${
        day >= 10 ? day : "0" + day
      }`;
      this.query.days = 30;
    },

    /**
     * 关房的房间item
     * @param {*} col
     * @param {*} item
     */
    getSolarItem(col, item) {
      return this.solarList.find(
        (o) => o.store_id == item.store_id && o.date == col.date
      );
    },
    /**
     * 取消所有选择
     */
    onCancel() {
      var c = document.getElementById("canvas");
      var ctx = c.getContext("2d");
      this.check_cell.forEach((item) => {
        var left = item.i * this.TD_WIDTH;
        var top = item.k * this.TD_HEIGHT;
        ctx.clearRect(left + 2, top + 2, this.TD_WIDTH - 4, this.TD_HEIGHT - 4);
      });
      this.check_cell = [];
    },

    /**
     * 关房弹框
     */
    onRoomClose() {
      this.closeForm = {
        type: 0,
      };
      this.visibleCloseRoomDialog = true;
    },
    /**
     * 关房提交
     */
    onSubmitClose() {
      this.closeForm.date_closes = [];
      if (this.check_cell.length <= 0) {
        return;
      }

      this.check_cell.forEach((item) => {
        var calendar = this.calendar_list[item.i];
        var store = this.store_list[item.k];
        this.closeForm.date_closes.push({
          store_id: store.id,
          date: calendar.date,
        });
      });

      this.$refs.closeForm.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/seller/v1/roomState/close", this.closeForm)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.dialogVisible = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loadSolar();
                this.visibleCloseRoomDialog = false;
                //取消全部选择
                this.onCancel();
              }
            });
        }
      });
    },

    /**
     * 开房提交
     */
    onOpenSubmit() {
      this.$confirm("您是否却要打开选择的关房？", "温馨提醒").then(() => {
        console.log(this.solar_selection_list);
        var array = this.solar_selection_list.map((item) => {
          return {
            store_id: item.store_id,
            date: item.date,
          };
        });
        this.$http.post("/seller/v1/roomState/open", array).then((res) => {
          this.saving = false;
          if (res.code == 0) {
            this.dialogVisible = false;
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.loadSolar();
            this.solar_selection_list = [];
          }
        });
      });
    },
    // /**
    //  * 净房、藏房转换
    //  */
    // onCleanToggle(item) {
    //   this.$confirm(item.clean_status == 0 ? "转为脏房" : "转为净房", "提示")
    //     .then(() => {
    //       this.$http
    //         .post("seller/v1/roomState/setRoomCleanStatus", {
    //           id: item.store_id,
    //           clean_status: item.clean_status == 0 ? 1 : 0,
    //         })
    //         .then((res) => {
    //           if (res.code == 0) {
    //             this.$message.success(res.msg);
    //             item.clean_status = item.clean_status == 0 ? 1 : 0;
    //           }
    //         });
    //     })
    //     .catch(() => {});
    // },
    /**
     * 打开预定
     */
    onOpenBookDialog() {
      this.checkList = [];
      if (this.check_cell.length > 0) {
        this.check_cell.forEach((item) => {
          var calendar = this.calendar_list[item.i];
          var store = this.store_list[item.k];
          this.checkList.push({
            store_id: store.id,
            check_in_date: calendar.date,
          });
        });
        this.isOpenBook = true;
      }
    },
    /**
     * 预定关闭
     */
    closeBook() {
      this.isOpenBook = false;
      this.copy_order_id = "";
      this.copy_store_id = "";
      this.copy_store = null;
      this.checkList = [];
      this.onCancel();
    },

    /**
     * 添加订单成功
     */
    addSuccess(item) {
      this.isOpenBook = false;
      this.current_order_id = item.id;
      this.order = item;
      this.loadOrder();
      this.onCancel();
    },

    /**
     * 订单详情关闭
     */
    onOrderClose() {
      this.loadCleanStores();
      this.loadOrder();
      this.current_order_id = null;
    },
    /**
     * 订单详情
     */
    onOpenDtl(item) {
      if (this.is_can_preview_dtl) {
        this.current_order_id = item.order_id;
      }
    },

    /**
     * 复制订单
     */
    onCopyOrder(item) {
      this.isOpenBook = true;
      this.copy_store_id = item.store_id;
      this.copy_order_id = item.order.id;
      this.copy_store = item;
    },

    // /**
    //  * 批量开关房回调
    //  */
    // onOffSuccess() {
    //   this.loadSolar();
    // },

    /**
     * 移除占用
     */
    onRmOccupy(item) {
      this.$confirm(`是否要移除占用?`, "温馨提示").then(() => {
        console.log(item);
        this.$http
          .get(`seller/v1/roomState/date_record/del?id=${item.id}`)
          .then((res) => {
            if (res.code == 0) {
              // this.loadDateRecord();
            }
          });
      });
    },

    /**
     * 剩余间数
     */
    residueRoomNumber(calc) {
      var time = new Date(calc.date).getTime();
      // var num = this.orderList.filter(
      //   (o) =>
      //     new Date(o.checkin_date).getTime() <= time &&
      //     time < new Date(o.checkout_date).getTime()
      // ).length;
      // return this.group_stores.counts - num;
      var differences = [];
      var list = this.orderList.filter(
        (o) =>
          new Date(o.checkin_date).getTime() <= time &&
          time < new Date(o.checkout_date).getTime()
      );

      this.group_stores.forEach((group) => {
        group.store_list.forEach((store) => {
          var i = list.findIndex((o) => o.store_id == store.id);
          if (i <= -1) {
            differences.push(0);
          }
        });
      });

      return differences.length;
    },

    /**
     * 鼠标移入
     */
    orderMouseenter(item) {
      item.t = setTimeout(() => {
        item.visiblePopover = true;
      }, 500);
    },

    /**
     * 订单鼠标移出
     */
    orderMouseleave(item) {
      item.l_t = setTimeout(() => {
        item.visiblePopover = false;
      }, 300);

      if (item.t) {
        clearTimeout(item.t);
        item.t = null;
      }
    },
    popoverMouseenter(item) {
      if (item.l_t) {
        clearTimeout(item.l_t);
        item.l_t = null;
      }
      item.visiblePopover = true;
    },

    popoverMouseleave(item) {
      item.visiblePopover = false;
    },

    /**
     * 拖拽开始
     */
    orderDragstart(e, item) {
      clearTimeout(item.t);
      item.is_draggable = true;
      item.drag = {
        start_offsetX: e.offsetX,
        start_offsetY: e.offsetY,
      };
      this.drag_item = item;
    },

    /**
     * 推拽移动
     */
    orderDrag(e) {
      if (e.offsetX < this.drag_item.drag.start_offsetX) {
        var x = this.drag_item.drag.start_offsetX - e.offsetX;
        this.drag_item.left_to = this.drag_item.left - x;
      } else {
        x = e.offsetX - this.drag_item.drag.start_offsetX;
        this.drag_item.left_to = this.drag_item.left + x;
      }
      if (e.offsetY < this.drag_item.drag.start_offsetY) {
        var y = this.drag_item.drag.start_offsetY - e.offsetY;
        this.drag_item.top_to = this.drag_item.top - y;
      } else {
        y = e.offsetY - this.drag_item.drag.start_offsetY;
        this.drag_item.top_to = this.drag_item.top + y;
      }

      this.drag_item.i_to = Math.floor(this.drag_item.left_to / this.TD_WIDTH);
      this.drag_item.k_to = Math.floor(this.drag_item.top_to / this.TD_HEIGHT);

      if (this.clientWidth - e.screenX < 40) {
        if (this.TempScrollLeft < this.ScrollMax) {
          this.TempScrollLeft += 20;
          this.$refs.horizontalScrollbar.setScrollLeft(this.TempScrollLeft);
        } else {
          this.TempScrollLeft = this.ScrollMax;
        }
      } else if (e.screenX != 0 && e.screenX < 300) {
        if (this.TempScrollLeft > 0) {
          this.TempScrollLeft -= 20;
          this.$refs.horizontalScrollbar.setScrollLeft(this.TempScrollLeft);
        } else {
          this.TempScrollLeft = 0;
        }
      }
    },

    /**
     * 拖拽放开
     */
    orderDrop() {
      if (!this.is_can_change) return;

      if (
        this.drag_item.i != this.drag_item.i_to ||
        this.drag_item.k != this.drag_item.k_to
      ) {
        //
        this.drag_to_item = Object.assign({}, this.drag_item);
        this.drag_to_item.left = this.drag_to_item.i_to * this.TD_WIDTH + 2;
        this.drag_to_item.top = this.drag_to_item.k_to * this.TD_HEIGHT + 2;
        //
        var calendar = this.calendar_list[this.drag_item.i_to];
        var store = this.store_list[this.drag_item.k_to];
        var dt = new Date(calendar.date);
        var date = dt.setDate(dt.getDate() + this.drag_item.days);
        this.$confirm(
          `由
          【${this.drag_item.order.contact_name}、${this.drag_item.house_no}、${
            this.drag_item.checkin_date_cn
          }~${this.drag_item.checkout_date_cn}、共${this.drag_item.days}晚】
          <br />更换至 <br />【${this.drag_item.order.contact_name}、${
            store.house_no
          }、${calendar.date_format_cn}~${date_util.shortCNDate(date)}、共${
            this.drag_item.days
          }晚】`,
          "温馨提示",
          {
            draggable: true,
            dangerouslyUseHTMLString: true,
          }
        )
          .then(() => {
            this.exchange_saving = true;
            this.$http
              .post("seller/v1/houseOrder/exchange", {
                order_store_id: this.drag_item.id,
                store_id: store.id,
                checkin_date: calendar.date,
              })
              .then((res) => {
                this.drag_item.is_draggable = false;
                this.drag_to_item = null;
                if (res.code == 0) {
                  this.$message.success(res.msg);
                  this.loadOrder();
                }
              })
              .finally(() => {
                this.exchange_saving = false;
              });
          })
          .catch(() => {
            this.drag_item.is_draggable = false;
            this.drag_to_item = null;
          });
      } else {
        this.drag_item.is_draggable = false;
        this.drag_to_item = null;
      }
    },

    /**
     * 房间鼠标移入
     */
    roomMouseEnter(o) {
      o.show_status = true;
    },
    /**
     *
     * @param {*} o
     */
    roomMouseLeave(o) {
      o.show_status = false;
    },

    /**
     * 脏房 净房转换
     * @param {*} o
     */
    roomStatusHandler(o) {
      this.$confirm(
        `您确定要将房间【${o.house_no}】转换【${
          o.clean_status == 0 ? "脏房" : "净房"
        }】？`,
        "温馨提示",
        {
          callback: (e) => {
            if (e == "confirm") {
              var status = o.clean_status == 0 ? 1 : 0;
              this.$http
                .post("seller/v1/roomState/setRoomCleanStatus", {
                  id: o.id,
                  clean_status: status,
                })
                .then((res) => {
                  if (res.code == 0) {
                    o.clean_status = status;
                  }
                });
            }
          },
        }
      );
    },

    /**
     * 打开备忘录详情
     * @param {*} item
     */
    onOpenNote(item) {
      this.current_note_dtl = item;
    },
  },
};
</script>
<style>
:root {
  --rms-td-width: 110px;
  --rms-td-height: 65px;
}
</style>
<style scoped>
.container {
  flex-grow: 1;
  margin-top: 20px;
  height: 1px;
  background: white;
  display: flex;
  flex-direction: column;
}

.container .header {
  height: 80px;
  border-bottom: 1px solid var(--el-border-color);
  display: flex;
  align-items: center;
}
.container .header .date.light {
  color: var(--el-color-danger);
}

.calendar_pick {
  width: 190px;
  height: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--el-border-color);
}

.calendar_pick .date_wap {
  position: relative;
  width: 100%;
}
.calendar_pick .date_wap .date_select {
  display: flex;
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.date_wap .btn_today {
  font-size: 12px;
  width: 16px;
  height: 16px;
  background: #409eff;
  color: white;
  margin-left: 15px;
  border-radius: 2px;
  line-height: 16px;
  text-align: center;
}

.calendar_pick .date_select_tip {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #999;
}

/* 日历标题 */
.header .calendar_box {
  flex-grow: 1;
  width: 1px;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.header .calendar_wrap {
  white-space: nowrap;
  position: absolute;
  display: flex;
}

.header .calendar_item {
  flex-shrink: 0;
  width: var(--rms-td-width);
  height: 80px;
  border-right: 1px solid var(--el-border-color);
  display: inline-block;
}

.header .calendar_item .date {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--el-border-color);
}
.header .calendar_item .number {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}

/* 日历内容 */
.container .body {
  flex-grow: 1;
  height: 1px;
}

.container .scroll_box {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.container .scroll_box .left {
  width: 190px;
}
.container .scroll_box .room_group {
  display: flex;
  width: 100%;
}

.container .scroll_box .group {
  flex-grow: 1;
  width: 1px;
  display: flex;
  padding: 0 5px;
  justify-content: center;
  align-items: center;
  border-right: 1px solid var(--el-border-color);
  border-bottom: 1px solid var(--el-border-color);
}

/* 房间 */
.container .rooms {
  width: 90px;
}
.container .scroll_box .rooms .item {
  height: var(--rms-td-height);
  width: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  border-bottom: 1px solid var(--el-border-color);
  border-right: 1px solid var(--el-border-color);
  cursor: pointer;
  padding: 3px;
  position: relative;
}

.container .scroll_box .rooms .item .house_no {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}
.container .scroll_box .rooms .item .house_no.dirty {
  background: #ccc;
}

/* 脏房标志 */
.container .rooms .item .house_no .dirty_icon {
  position: absolute;
  right: 5px;
  bottom: 5px;
  transform: rotate(180deg);
}

.container .rooms .item .roomStatus {
  position: absolute;
  z-index: 1;
  left: 3px;
  top: 3px;
  bottom: 2px;
  right: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--el-color-primary);
  /* display: none; */
}

/* 日历框 */
.container .canvas_box {
  flex-grow: 1;
  width: 1px;
  position: relative;
  overflow: hidden;
}

.container .canvas_box .canvas_wrap {
  position: absolute;
}

/* 已经占用的房间-日期 */
.canvas_wrap .occupy {
  position: absolute;
  z-index: 1;
  width: 96px;
  height: 46px;
  border-radius: 4px;
  background: #c8c9cc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* 操作工具栏 */
.canvas_wrap .tooltip {
  position: absolute;
  z-index: 5;
  background: #444;
  border-radius: 5px;
  width: 45px;
}
.canvas_wrap .tooltip .arrows {
  position: absolute;
  background: #444;
  width: 10px;
  height: 10px;
  z-index: -1;
  transform: rotate(45deg);
}

.canvas_wrap .tooltip .tools .btn {
  color: white;
  padding: 8px 0;
  text-align: center;
  cursor: pointer;
}
.canvas_wrap .tooltip .tools .btn:hover {
  background: #000;
}
.canvas_wrap .tooltip .tools .btn:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.canvas_wrap .tooltip .tools .btn:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* 订单 */
.canvas_box .order {
  position: absolute;
  z-index: 2;
  height: calc(var(--rms-td-height) - 4px);
  border-radius: 4px;
  padding: 8px 10px;
  overflow: hidden;
  cursor: pointer;
}
.order .cover {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 4px;
  color: #000;
  overflow: hidden;
}

/* 订单状态 */
.order .status {
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 20px;
  transform: rotate(-35deg);
  background-color: white;
  left: -14px;
  top: -10px;
}

/* 订单预览框金额 */
.order .order_amount_wrp {
  margin-top: 10px;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  padding: 10px 0;
}

/* 欠费 */
.order .owe {
  background: red;
  color: white;
  font-size: 12px;
  width: 20px;
  position: absolute;
  z-index: 2;
  height: 20px;
  right: 2px;
  top: 2px;
  line-height: 20px;
  border-radius: 2px;
  text-align: center;
  /* transform:scale(.8); */
}

/* 拖拽 */
.order.drag {
  cursor: move;
}
.order.dragstart {
  opacity: 0.5;
  border: 2px dashed #000;
}

/* 关房 */
.solar {
  position: absolute;
  z-index: 4;
  background: #b1b3b8;
  border-radius: 4px;
  width: calc(var(--rms-td-width) - 4px);
  height: calc(var(--rms-td-height) - 4px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.solar .text {
  font-size: 12px;
}

.solar .checked {
  position: absolute;
  top: 0;
  right: 0;
  background: blue;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solar_desc {
  width: 200px;
}

/* 横向滚动条 */
.horizontal_scrollbar {
  position: fixed;
  bottom: 0;
  left: 120px;
  right: 20px;
  display: flex;
  align-items: center;
}
.horizontal_scrollbar .left {
  width: 190px;
}
.horizontal_scrollbar .right {
  flex-grow: 1;
  width: 1px;
}

.horizontal_scrollbar_inner {
  height: 25px;
  width: calc(var(--rms-td-width) * 30);
}

.ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-align: center;
}
.ellipsis-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-align: center;
}

/* 开关房工具栏 */

.canvas_wrap .solar_tool {
  position: absolute;
  z-index: 5;
  background: #444;
  border-radius: 5px;
  width: 45px;
}
.canvas_wrap .solar_tool .arrows {
  position: absolute;
  background: #444;
  width: 10px;
  height: 10px;
  z-index: -1;
  transform: rotate(45deg);
}

.canvas_wrap .solar_tool .tools .btn {
  color: white;
  padding: 8px 0;
  text-align: center;
  cursor: pointer;
}
.canvas_wrap .solar_tool .tools .btn:hover {
  background: #000;
}
.canvas_wrap .solar_tool .tools .btn:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.canvas_wrap .solar_tool .tools .btn:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>

<style>
.room_state_calendar .el-date-editor--date {
  opacity: 0;
}

/* .room_state_calendar .el-scrollbar__bar.is-horizontal {
  height: 10px !important;
} */
</style>
