<!--
 * @Description: 下单、预定
 * @Author: 琢磨先生
 * @Date: 2022-07-07 13:50:03
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-03-12 16:27:39
-->
<template>
  <el-drawer
    v-model="visibleDrawer"
    size="800px"
    :destroy-on-close="true"
    :show-close="true"
    @closed="onClosed"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <template #header>
      <h4 class="title">预定</h4>
    </template>
    <el-form :model="form" ref="form" :rules="rules">
      <el-divider direction="horizontal" content-position="left">
        <b>基本信息</b>
      </el-divider>
      <el-row>
        <el-col :span="8" :offset="0">
          <el-form-item label="类型" prop="type">
            <el-select v-model="form.type" placeholder>
              <el-option
                :label="item.text"
                :value="item.value"
                v-for="item in enums.order_types"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form-item label="渠道" prop="ota_id">
            <el-select v-model="form.ota_id" placeholder>
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in ota_list"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8" :offset="0">
          <el-form-item label="姓名" prop="contact_name">
            <el-input v-model="form.contact_name" placeholder></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form-item label="手机号" prop="mobile_phone">
            <el-input v-model="form.mobile_phone" placeholder></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-divider direction="horizontal" content-position="left">
        <b>房间信息</b>
      </el-divider>
      <edit-room
        :choose_room="room_list"
        :group_stores="group_stores"
        :order_stores="order_stores"
        @change="roomEditChange"
        @check-change="priceCheckChange"
      ></edit-room>
      <!-- 收款信息 -->
      <edit-receipt
        :enums="enums"
        @change="receiptChange"
        :amount="totalAmount"
      ></edit-receipt>
      <!-- 收款信息  END-->
      <el-divider direction="horizontal" content-position="left"
        >备注信息</el-divider
      >

      <el-form-item label="操作备注">
        <el-input
          v-model="form.remark"
          type="textarea"
          rows="4"
          placeholder="备注信息"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="flex-row flex-between">
        <div class="total">
          订单金额：
          <span>¥{{ totalAmount }}</span>
        </div>
        <el-button
          type="primary"
          :loading="saving"
          @click="onSubmit"
          :disabled="checking"
          >提交订单</el-button
        >
      </div>
    </template>
  </el-drawer>
</template>

<script>
import common_api from "@/http/common_api";
import ota_api from "@/http/ota_api";
import EditRoom from "./add/edit_room.vue";
import EditReceipt from "./add/edit_receipt.vue";
export default {
  components: {
    EditRoom,
    EditReceipt,
  },
  data() {
    return {
      visibleDrawer: true,
      saving: false,
      loading: false,
      form: {
        type: 0,
      },
      rules: {
        contact_name: [{ required: true, message: "请输入", trigger: "blur" }],
        // mobile_phone: [{ required: true, message: "请输入", trigger: "blur" }],
        ota_id: [{ required: true, message: "请选择", trigger: "blur" }],
        type: [{ required: true, message: "请选择", trigger: "blur" }],
      },
      enums: {},
      //渠道
      ota_list: [],
      //传递进来的选择数据
      // checkRoomList: [],
      //检测选择的房源数据返回结果列表
      accomRoomList: [],
      //房源库存
      storeList: [],
      //收款数据列表
      receiptList: [],
      //总额
      totalAmount: 0,
      //分组的房屋选则
      groupList: [],
      //
      order_stores: [],
      //房费修改校验中...
      checking: false,
    };
  },
  props: ["room_list", "group_stores", "order_id", "store_id", "copy_store"],
  emits: ["close", "success"],
  watch: {
    order_id: {
      handler() {
        // if (this.order_id) {
        //   this.loading = true;
        //   this.$http
        //     .get("seller/v1/houseorder/dtl?id=" + this.order_id)
        //     .then((res) => {
        //       this.loading = false;
        //       var order = res.data;
        //       this.form = {
        //         type: order.type,
        //         ota_id: order.ota_id,
        //         contact_name: order.contact_name,
        //         mobile_phone: order.mobile_phone,
        //       };
        //       this.order_stores = [];
        //       order.order_stores.forEach((x) => {
        //         if (
        //           x.store_id == this.store_id &&
        //           !this.order_stores.find((o) => o.store_id == x.store_id)
        //         ) {
        //           this.order_stores.push(x);
        //         }
        //       });
        //     });
        // }
      },
      immediate: true,
    },
    //复制
    copy_store: {
      handler() {
        if (this.copy_store && this.copy_store.id) {
          //
          var order = this.copy_store.order;
          this.form = {
            type: order.type,
            ota_id: order.ota_id,
            contact_name: order.contact_name,
            mobile_phone: order.mobile_phone,
          };
          this.order_stores = [];
          this.order_stores.push(Object.assign({}, this.copy_store));
        }
      },
      immediate: true,
    },
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });

    ota_api.get_otas().then((res) => {
      if (res.code == 0) {
        this.ota_list = res.data;
      }
    });
  },
  methods: {
    /**
     * 分组
     */
    handleGroup(arr) {
      var obj = {};
      var groups = [];
      arr.forEach((item) => {
        if (!obj[item.id]) {
          obj[item.id] = item;
          groups.push({
            key: item.id,
            items: [item],
          });
        } else {
          groups.find((v) => {
            if (v.key === item.id) {
              v.items.push(item);
            }
          });
        }
      });
      return groups;
    },

    /**
     * 房间库存选择更改
     */
    storeChange() {
      this.onGetCheck();
    },

    /**
     * 房间选择更新
     */
    roomEditChange(list, amount) {
      this.accomRoomList = list;
      this.totalAmount = amount;
    },

    /**
     * 收款信息
     */
    receiptChange(list) {
      console.log(list);
      this.receiptList = list;
    },

    /**
     * 关闭
     */
    onClosed() {
      this.$emit("close");
    },

    /**
     * 修改金额校验中
     */
    priceCheckChange(result) {
      if (result) {
        this.checking = true;
      } else {
        this.checking = false;
      }
    },

    /**
     *  提交下订单 延迟300ms执行，等待房间校验回调
     */
    onSubmit() {
      setTimeout(() => {
        this.form.accoms = this.accomRoomList;
        this.form.receipts = this.receiptList;
        for (var i = 0; i < this.form.accoms.length; i++) {
          var item = this.form.accoms[i];
          if (!item.store_id) {
            this.$message.error("请选择房屋");
            return;
          }
        }
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.saving = true;
            this.$http
              .post("/seller/v1/houseOrder/submit", this.form)
              .then((res) => {
                this.saving = false;
                if (res.code == 0) {
                  // this.dialogVisible = false;
                  this.$message({
                    type: "success",
                    message: res.msg,
                  });
                  // this.visibleDrawer = false;
                  // this.receiptList = [];
                  // this.form = {};
                  // if (res.data.code_url) {
                  //   this.code_url = res.data.code_url;
                  //   this.qrcodeAmount = res.data.wx_pay_code_amount;
                  // } else {
                  //   this.visibleDrawer = false;
                  // }
                  this.$emit("success", res.data);
                }
              })
              .cath(() => {
                this.saving = false;
              });
          }
        });
      }, 300);
    },
  },
};
</script>

<style scoped>
.title {
  line-height: 23px;
  font-size: 18px;
  margin: 0;
}

.room_list .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.room_list .item .el-date-picker {
  width: 80px;
}

.room_list .item .el-select {
  flex-grow: 1;
  width: auto;
}

.room_list .item .el-input {
  width: 140px;
}

.room_list .buttons {
  margin-top: 10px;
}

.total {
  font-size: 18;
  font-weight: 600;
}

.total span {
  color: #409eff;
}

/* 价格列表 */
.date_price_height {
  max-height: 500px;
  overflow-y: auto;
}

.date_price_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 20px;
  column-gap: 20px;
}

/* .date_price_grid .grid_item {} */

.date_price_grid .grid_item .date {
  font-weight: 600;
}

.date_picker .el-input__wrapper {
  border-radius: 0px;
}
</style>
