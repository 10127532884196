<!--
 * @Description: 备忘录详情
 * @Author: 琢磨先生
 * @Date: 2024-01-22 17:46:33
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-31 16:05:30
-->
<template>
  <el-drawer
    title="备忘录详情"
    v-model="visible"
    direction="rtl"
    size="900px"
    custom-class="drawer_custom"
    :destroy-on-close="true"
    :show-close="true"
    @closed="onClosed"
  >
    <div
      style="display: flex; flex-grow: 1; height: 1px; flex-direction: column"
      v-loading="loading"
    >
      <el-tabs>
        <el-tab-pane label="详情">
          <el-scrollbar>
            <el-descriptions :column="4">
              <el-descriptions-item label="类型"
                ><b>{{ model.typeItem?.name }}</b></el-descriptions-item
              >
              <el-descriptions-item label="关联房间"
                ><b>{{ model.store?.house_no }}</b></el-descriptions-item
              >
              <el-descriptions-item label="状态">
                <el-link v-if="model.status == 0" :underline="false">{{
                  model.status_text
                }}</el-link>
                <el-link
                  type="warning"
                  v-else-if="model.status == 10"
                  :underline="false"
                  >{{ model.status_text }}</el-link
                >
                <el-link
                  type="primary"
                  v-else-if="model.status == 20"
                  :underline="false"
                  >{{ model.status_text }}</el-link
                >
                <el-link
                  type="success"
                  v-else-if="model.status == 30"
                  :underline="false"
                  >{{ model.status_text }}</el-link
                >
                <el-link v-else>{{ model.status_text }}</el-link>
              </el-descriptions-item>
              <el-descriptions-item label="时间"
                ><b>{{ model.create_at }}</b></el-descriptions-item
              >
            </el-descriptions>
            <el-alert type="info" :closable="false" title=""
              ><div v-html="model.content"></div>
              <div class="pic-box" v-if="model.pic_urls">
                <div
                  class="picture"
                  v-for="(url, index) in model.pic_urls"
                  :key="url"
                >
                  <el-image
                    style="width: 60px; height: 60px"
                    :src="url"
                    :preview-src-list="model.pic_urls"
                    :initial-index="index"
                    fit="cover"
                  />
                </div>
              </div>
            </el-alert>
            <el-divider content-position="left">回复&评论</el-divider>
            <el-timeline>
              <el-timeline-item
                v-for="(item, index) in model.discussList"
                :key="index"
                placement="top"
                :timestamp="`${item.create_at} ${item.emp?.chs_name}`"
              >
                <el-alert type="info" title="" :closable="false">
                  <div v-html="item.content"></div>
                  <div class="pic-box" v-if="item.pic_urls">
                    <div
                      class="picture"
                      v-for="(url, index) in item.pic_urls"
                      :key="url"
                    >
                      <el-image
                        style="width: 50px; height: 50px"
                        :src="url"
                        :preview-src-list="item.pic_urls"
                        :initial-index="index"
                        fit="cover"
                      />
                    </div>
                  </div>
                </el-alert>
                <el-popconfirm
                  title="确定要删除？"
                  v-if="item.isCanDelete"
                  @confirm="onDelete(item)"
                >
                  <template #reference>
                    <el-button
                      type="danger"
                      link
                      size="small"
                      :loading="item.deleting"
                      >删除</el-button
                    >
                  </template>
                </el-popconfirm>
              </el-timeline-item>
            </el-timeline>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane label="日志记录">
          <operate-log-list
            code="9501"
            :primaryKey="item.id"
          ></operate-log-list>
        </el-tab-pane>
      </el-tabs>
    </div>
    <template #footer>
      <el-button link size="small" icon="edit" @click="onEdit">编辑</el-button>
      <el-button type="primary" @click="onOpenDiscuss">回复</el-button>
    </template>
  </el-drawer>

  <el-dialog
    title="添加回复"
    v-model="visibleDialog"
    width="800px"
    top="10vh"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    draggable
    :close-on-press-escape="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="内容" prop="content">
        <el-col :span="20">
          <el-input
            v-model="form.content"
            placeholder
            :rows="8"
            type="textarea"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="图片">
        <upload-gallery
          :pictures="form.pic_urls"
          @change="uploadChange"
        ></upload-gallery>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-dialog>
  <note-edit
    :item="current_edit"
    @closed="current_edit = null"
    @success="onSuccess"
  ></note-edit>
</template>

<script>
import UploadGallery from "@/views/psd/upload_gallery.vue";
import NoteEdit from "./note_edit.vue";
import OperateLogList from "@/views/components/operate_log_list.vue";
export default {
  components: {
    UploadGallery,
    NoteEdit,
    OperateLogList,
  },
  data() {
    return {
      loading: false,
      visible: false,
      saving: false,
      visibleDialog: false,
      model: {},
      form: {},
      rules: {
        content: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
      },
      current_edit: null,
    };
  },
  props: {
    item: Object,
  },
  emits: ["close", "change"],
  created() {},
  watch: {
    item: {
      handler() {
        this.id = null;
        if (this.item) {
          this.id = this.item.id;
          this.visible = true;
          this.loadDtl();
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     *
     */
    onClosed() {
      this.$emit("close");
    },

    /**
     * 加载项
     */
    loadDtl() {
      this.loading = true;
      this.$http
        .get("seller/v1/note/dtl?id=" + this.id)
        .then((res) => {
          if (res.code == 0 && res.data) {
            this.model = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },

    /**
     * 修改备忘录
     */
    onEdit() {
      this.current_edit = this.model;
    },

    /**
     * 备忘录修改成功
     */
    onSuccess() {
      this.loadDtl();
      this.$emit("change");
    },

    /**
     * 打开回复框
     */
    onOpenDiscuss() {
      this.visibleDialog = true;
    },
    /**
     * 回复图片上传回调
     * @param {*} urls
     */
    uploadChange(urls) {
      this.form.pic_urls = urls;
    },
    /**
     * 提交回复
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.form.note_id = this.id;
          this.$http
            .post("/seller/v1/note/discuss/edit", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.visibleDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loadDtl();
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },

    /**
     * 删除回复
     * @param {*} item
     */
    onDelete(item) {
      item.deleting = true;
      this.$http
        .get("seller/v1/note/discuss/del?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.loadDtl();
          }
        })
        .finally(() => (item.deleting = false));
    },
  },
};
</script>

<style>
.pic-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.pic-box .picture {
  margin-right: 10px;
  margin-top: 10px;
}
.el-drawer__body {
  display: flex;
  flex-direction: column;
}

.drawer_custom .el-tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 1px;
}

.drawer_custom .el-tabs .el-tabs__content {
  flex-grow: 1;
  height: 1px;
}
.drawer_custom .el-tabs .el-tabs__content .el-tab-pane {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
